import React from 'react';
import SearchBar from '../searchbar/SearchBar';
import HeaderBar from '../HeaderBar';
import myImage from '../../images/illustration.png';

const MainPage = () => {

    const imageStyle = {
        width: '100%',
        height: 'auto',
        maxWidth: '500px',
        maxHeight: '500px',
    }


    return (
        <>
            <div className="flex flex-col h-screen ">
                <HeaderBar/>
                <div className="flex flex-grow items-center ">

                    <div className="flex flex-grow flex-col justify-center items-center lg:flex-row ">
                        <div className="flex items-center justify-center max-w-[100px] md:max-w-full">
                            <img
                                src={myImage}
                                alt="Someone looking using their phone to find a product"
                                style={imageStyle}
                            />
                        </div>
                        <div className="flex flex-col items-center justify-center space-y-8 ">
                            <div className="font-ls-light text-2xl md:text-3xl ">Find products in your local area</div>
                            <div className="w-full h-48   md:h-14 ">
                                <SearchBar/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MainPage;
