import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {MsalProvider} from "@azure/msal-react";
import {AuthProvider} from "./context/AuthContext";
import {PublicClientApplication} from "@azure/msal-browser";
import {msalConfig} from "./auth/authConfig";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

const pca = new PublicClientApplication(msalConfig);

root.render(
    // <React.StrictMode>
    <MsalProvider instance={pca}>
        <AuthProvider>
            <QueryClientProvider client={queryClient}>
                <App/>
                {/* <ReactQueryDevtools initialIsOpen={false} /> */}
            </QueryClientProvider>
        </AuthProvider>
    </MsalProvider>
    // </React.StrictMode>
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
