import React, {useContext} from 'react';
import MainPage from './components/home/MainPage';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Results from './components/results/Results';
import {AuthContext} from "./context/AuthContext";
import Login from "./auth/Login";
import YCIntro from "./components/yc/YCIntro";
import {AuthenticatedTemplate, UnauthenticatedTemplate} from "@azure/msal-react";

function App() {

    const {isAuthenticated, isYCombinator} = useContext(AuthContext);

    console.log(`isAuthenticated: ${isAuthenticated}`);
    console.log(`isYCombinator: ${isYCombinator}`);

    return (
        <>
            <Router>
                <AuthenticatedTemplate>
                    <Routes>
                        <Route path="/login" element={<Login/>}/>
                        {/*<Route path="/" element={isAuthenticated ? <MainPage /> : <Navigate to="/login" replace />} />*/}
                        <Route path="/" element={<MainPage/>}/>
                        <Route path="/search" element={<Results/>}/>
                        <Route path="/ycIntro" element={<YCIntro/>}/>
                    </Routes>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Login/>
                </UnauthenticatedTemplate>
            </Router>
        </>


    );
}

export default App;
