export const msalConfig = {
  auth: {
    clientId: "087c8ffe-40b2-4635-89a3-7e57202437bc", // your Azure AD B2C App Registration client (application) ID
    authority: "https://phindb2c.b2clogin.com/phindb2c.onmicrosoft.com/B2C_1_PhindB2C_Sign_In",
    knownAuthorities: ["phindb2c.b2clogin.com"],
    redirectUri: 'https://www.phindit.com/login',
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

export const loginRequest = {
  scopes: ['https://phindb2c.onmicrosoft.com/087c8ffe-40b2-4635-89a3-7e57202437bc/PhindSearchUIProd']
}