import React from 'react';
import StoreCard from './StoreCard';

const StoreCardList = ({ stores, searchQuery, selectedStore, storeCardClick }) => {
  stores.sort((a, b) => a.store.distanceFromUser - b.store.distanceFromUser);
  //console.log(stores);
  return (
    <div className="">
      {stores.length > 0 ? (
        <>
          {/*<p>Search results for "{searchQuery}"</p>*/}
          {stores.map((store) => {
            //console.log(store);
            //console.log(selectedStore);
            const storeSelected = selectedStore && store.store.id === selectedStore.id
            //console.log(store.products);
            return (<StoreCard key={store.store.id} store={store.store} products={store.products} isSelected={storeSelected} storeCardClick={storeCardClick} />)
          })}
        </>
      ) : (
        <p>no results found</p>
      )}
    </div>
  );
};

export default StoreCardList;
