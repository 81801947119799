import React, {useState} from 'react';
import {ChevronDownIcon} from '@heroicons/react/24/solid';
import {ChevronDoubleUpIcon} from "@heroicons/react/24/solid";
import ProductCard from "./ProductCard";

const StoreCard = ({ store, products, isSelected, storeCardClick}) => {
    const initialDisplayedProducts = 2
    const [displayedProducts, setDisplayedProducts] = useState(initialDisplayedProducts);

    const handleShowMore = () => {
        setDisplayedProducts((prevDisplayed) => prevDisplayed + initialDisplayedProducts);
    };
    const handleShowLess = () => {
        if (displayedProducts > initialDisplayedProducts) {
            setDisplayedProducts(initialDisplayedProducts);
        }
    };

    const rounded = (store.distanceFromUser / 1000).toFixed(1);
    const distanceInMile = rounded === '0.0' ? 'less than 0.1' : rounded;

    return (
        <div
            className={`bg-white rounded-lg shadow-md px-4 pt-4 pb-2 my-2 ${isSelected ? 'border-2 border-secondary ' : 'border border-tertiary'}`}
            onClick={() => storeCardClick(store)}>

            <h2 className="text-2xl lg:flex lg:justify-between flex-col lg:flex-row">
                <div className="flex">
                    <span>{store.name}</span>
                    <span className='font-ls-light italic whitespace-pre'> - {store.location_name}</span></div>
                <div className="ml-auto flex">
                    <span className="font-ls-regular text-sm whitespace-pre">{products.length + ` product${products.length > 1 ? 's' : ''}`}</span>
                    <span className="font-ls-regular text-sm italic whitespace-pre ">{", " + distanceInMile + " miles"}</span>
                </div>

            </h2>
            <div className="">
                <ul>
                    {products.slice(0, displayedProducts).map((product, index) => (
                        <li key={index}
                            className={`${index === products.length - 1 ? '' : 'border-b'} py-2 ${isSelected ? 'border-secondary ' : 'border-tertiary'}`}>
                            <ProductCard key={`${product.id}`} nameDescription={product.name} keyPhrases={product.tags} price={product.price}/>
                        </li>
                    ))}
                </ul>
            </div>

            <div className={"space-x-2 flex justify-end mr-1"}>
                {displayedProducts < products.length && (
                    <button
                        className={`bg-blue-500 text-white p-1 rounded-full mt-2 border  ${isSelected ? ' border-secondary ' : ' border-tertiary'} hover:shadow-md`}
                        onClick={handleShowMore}
                    >
                        <ChevronDownIcon className={`h-3 w-3 ${isSelected ? 'text-secondary ' : 'text-tertiary'} `}/>
                    </button>
                )}
                {displayedProducts > initialDisplayedProducts && (
                    <button
                        className={`bg-blue-500 text-white p-1 rounded-full mt-2 border ${isSelected ? ' border-secondary ' : ' border-tertiary'} hover:shadow-md`}
                        onClick={handleShowLess}
                    >
                        <ChevronDoubleUpIcon className={`h-3 w-3 ${isSelected ? 'text-secondary ' : 'text-tertiary'}`}/>
                    </button>
                )}

            </div>

        </div>
    );
};

export default StoreCard;
