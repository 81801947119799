import {useEffect, useState} from 'react'
import {Combobox} from '@headlessui/react'
import axios from "axios";
import {useQuery} from "@tanstack/react-query";
import { MapPinIcon } from '@heroicons/react/24/outline';


const fetchLocations = async (searchTerm) => {
    const response = await axios.get(`https://phind-search.azurewebsites.net/api/google_places_search?code=B2QipkNKqteCihqfBctbT7I2QBQPHfp9SY5zctr4EiLnAzFu6Vlm-A==&searchTerm=${searchTerm}&noResults=5`);
    return response.data.splice(0, 3);
};

const fetchFullLocation = async (placeId) => {
    const response = await axios.get(`https://phind-search.azurewebsites.net/api/google_place_from_id?code=mSOw_jkaar9ymZtenMyJG86SC6rFk2fm2_2mvMTc3YJbAzFuE0_q0Q==&placeId=${placeId}`);
    return response.data;
}


export default function HeadlessAutoLocation({onLocationChange, locationString = ''}) {

    const [openOptions, setOpenOptions] = useState(false);
    const [searchTerm, setSearchTerm] = useState(locationString);
    const [selected, setSelected] = useState({placeId: '', description: locationString})

    const {data: returnedLocations = [], refetch} = useQuery(
        ['locations', searchTerm],
        () => fetchLocations(searchTerm),
        {}
    );
    const {data: selectedPlace, refetch: refetchSelectedPlace} = useQuery(
        ['selectedPlace', selected.placeId],
        () => fetchFullLocation(selected.placeId),
        {
            enabled: selected.placeId !== '',
            retry: false
        }
    );
    const handleBlur = () => {
        setTimeout(() => {
            setOpenOptions(false);
        }, 150);
    };
    useEffect(() => {
        if (searchTerm) {
            refetch();
        }
    }, [searchTerm, refetch]);

    useEffect(() => {
        if (selected && selected.placeId !== '') {
            refetchSelectedPlace();
        }
    }, [selected, refetchSelectedPlace]);

    useEffect(() => {
        if (selectedPlace) {
            if (selectedPlace.name === "currentlocation") {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const currentLocation = {
                            placeId: "currentlocation",
                            address: "Current Location",
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        };
                        onLocationChange(currentLocation);
                    },
                    (error) => {
                        console.error(error);
                    }
                );
            } else {
                onLocationChange(selectedPlace);
            }
        }
    }, [selectedPlace, onLocationChange]);

    const onComboBoxItemSelected = (value) => {
        if (value.description === "Current Location") {
            const currentLocation = {
                placeId: "currentlocation",
                description: "Current Location",
            };
            setSelected(currentLocation);
            setOpenOptions(false);
        } else {
            const selectedLocation = returnedLocations.find(loc => loc.description === value);
            setSelected(selectedLocation);
            setOpenOptions(false);
        }
        setOpenOptions(false);
    };

    return (
        <div className="w-full z-20">
            <Combobox value={(selected.description)} onChange={(value) => onComboBoxItemSelected(value)}>
                <div className="relative h-full w-full">
                    <Combobox.Input
                        className={`w-full px-3 h-full text-lg rounded-lg focus:outline-none`}
                        onChange={(event) => setSearchTerm(event.target.value)}
                        placeholder="near..."
                        onFocus={() => setOpenOptions(true)}
                        onBlur={handleBlur}
                    />

                    <Combobox.Options static={openOptions}
                                      className={`${(returnedLocations.length > 0 || searchTerm === '') ? 'visible' : 'invisible'} w-full  absolute overflow-auto border-b border-x border-secondary rounded-b-lg bg-white`}
                    >
                        {searchTerm === '' ? (
                            <Combobox.Option className="p-3 hover:bg-tertiary border-t border-secondary"
                                             key={"currentlocation"}
                                             value={{description: "Current Location"}}>
                                <MapPinIcon className="h-5 w-5 mr-2 inline-block"/>
                                <span>Use Current Location</span>
                            </Combobox.Option>
                        ) : (
                            returnedLocations.map((location) => (
                                <Combobox.Option
                                    className={`p-3 hover:bg-tertiary border-t border-secondary`}
                                    key={location.placeId}
                                    value={location.description}>
                                    <span>{location.description}</span>
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </div>
            </Combobox>
        </div>
    )
}
