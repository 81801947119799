import React from "react";


const ProductInput = ({value, handleInputChange}) => {

    return (

        <input
            type="text"
            placeholder="Search for product"
            className={`w-full px-3 h-full text-lg rounded-lg justify-center focus:outline-none`}
            value={value}
            onChange={handleInputChange}
            onClick={(event) => event.target.select()}
        />
    )
}
export default ProductInput;