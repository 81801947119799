import React from 'react';
import {PhotoIcon} from '@heroicons/react/24/solid';

const ProductCard = ({
                         imageUrl,
                         nameDescription = "",
                         keyPhrases,
                         price
                     }) => {
    return (
        <div className=" flex flex-row bg-white  p-2 space-x-3 ">
            {/*<div className="  bg-tertiary justify-center rounded w-20 h-20">*/}
            {/*    {imageUrl ? <img src={imageUrl} alt="Product"/> : <PhotoIcon className="fill-white w-20"/>}*/}
            {/*</div>*/}
            <div className="">
                <div className="">
                    <span className="ml-1">{nameDescription + " - "}</span>
                    {price ? <span className="font-ls-bold text-sm">{`£${price.toFixed(2)}`}</span> : null}
                </div>
                <div className="flex flex-wrap gap-1">{keyPhrases.map((phrase, index) => {
                    return (
                        <span key={index}
                              className="border border-tertiary rounded-full px-2  text-sm overflow-hidden inline-block align-middle leading-normal">{phrase}</span>
                    )
                })}</div>
                <div>
                    {/*<span className="font-ls-bold text-sm">{`£${price.toFixed(2)}`}</span>*/}
                    {/*{price ? <span className="font-ls-bold text-sm">{`£${price.toFixed(2)}`}</span> : null}*/}
                    {/*<span className="font-ls-regular italic text-sm">{` - ${units}`}</span>*/}
                </div>
            </div>
        </div>
    );
};

export default ProductCard;
