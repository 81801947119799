import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import React, {createContext} from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const isAuthenticated = useIsAuthenticated();

    const { accounts } = useMsal();

    const account = accounts[0];

    let isYCombinator = false;
    if (account) {
        const ycToken = account.idTokenClaims['extension_isYC'];
        if (ycToken) {
            isYCombinator = ycToken;
        } else {
            isYCombinator = false;
        }
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, isYCombinator: isYCombinator }}>
            {children}
        </AuthContext.Provider>
    );
};
