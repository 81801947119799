import React from "react";
import {useNavigate} from "react-router-dom";

function SignInButton() {

    const navigate = useNavigate();
    const handleLogin = () => {
        navigate("/login");
    };

    return (
        <button className={`border py-1.5 px-2.5 rounded-lg shadow w-24 ml-8`}
            onClick={handleLogin}>
            Sign In
        </button>
    );
}
export default SignInButton;