import axios from "axios";

// const baseUrl = " http://localhost:7071/api/search_old?"
const baseUrl = "https://phind-search.azurewebsites.net/api/search_old?code=oJoZ-F35hHsEC976SGNbTEn-Ydfd_uE7NiHeUZ1cE-g5AzFuYXFNqg=="
const search = async (token, query, lat, long, radiusInMiles) => {

    const url = baseUrl + `&searchTerm=${encodeURIComponent(query)}&lat=${lat}&long=${long}&radius=${radiusInMiles}`
    console.log(token);
    const { data } = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return data;
};
export default search;