import React, {useRef, useState} from 'react';
import {GoogleMap, InfoWindow, LoadScriptNext, MarkerF} from '@react-google-maps/api';
import mapPinImage from '../../images/green-blackdotborder.png';
import userLocationPinImage from '../../images/map-location-dot-opaque.png';


const containerStyle = {
    width: '100%',
    height: '100%'
};

const mapStyles = [
    {
        featureType: 'poi',
        elementType: 'labels',
        stylers: [
            {visibility: 'off'} // Hide POI (Points of Interest) labels, including landmarks, tube stations, and train stations
        ]
    }
];

const Map = ({lat, long, results, selectedStore, handleStoreClick}) => {

    const [isMapLoaded, setIsMapLoaded] = useState(false);
    //const [selectedStore, setSelectedStore] = useState(null);
    const mapRef = useRef(null);

    const center = {
        lat: lat,
        lng: long
    };

    const distinctStores = getDistinctStoresWithProductCount(results);

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
        setIsMapLoaded(true);
    }, [distinctStores]);

    return (

        <LoadScriptNext googleMapsApiKey="AIzaSyDyn8FSTZ0xIJvqCjVhhte5jvbUUSBcR24">

                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={12}
                    onLoad={onMapLoad}
                    options={{
                        styles: mapStyles,
                        zoomControl: false,
                        mapTypeControl: false,
                        streetViewControl: false,

                    }}

                >
                    {isMapLoaded && distinctStores.map((store) => (
                        <MarkerF
                            key={store.id}
                            position={{lat: store.latitude, lng: store.longitude}}
                            onClick={() => handleStoreClick(store)}
                            icon={{
                                url: mapPinImage, // Specify the path to your custom PNG image
                                scaledSize: new window.google.maps.Size(40, 40), // Adjust the size of the icon as needed
                            }
                            }
                        />
                    ))}

                    {isMapLoaded && (
                        <MarkerF
                            key={'userLocation'}
                            position={{lat: lat, lng: long}}
                            icon={{
                                url: userLocationPinImage, // Specify the path to your custom PNG image
                                scaledSize: new window.google.maps.Size(30, 30), // Adjust the size of the icon as needed
                            }
                            }
                        />
                    )}
                    {selectedStore && (
                        <InfoWindow
                            position={{lat: selectedStore.latitude, lng: selectedStore.longitude}}
                            onCloseClick={() => handleStoreClick(null)}
                        >
                            <div className='border-y border-secondary p-1'>
                                <h4>
                                    <span className='font-ls-semibold'>{selectedStore.name} </span>
                                </h4>
                                <p className=''>
                                    <span className='font-ls-regular italic'>{selectedStore.location_name}</span>
                                    <span
                                        className='font-ls-light italic text-xs'>, {selectedStore.distanceFromUser / 1000}miles</span>
                                </p>
                            </div>
                        </InfoWindow>
                    )}
                </GoogleMap>

        </LoadScriptNext>

    );
}

const getDistinctStoresWithProductCount = (data) => {
    const storeCounts = {};

    data.forEach(item => {
        item.store.forEach(store => {
            if (storeCounts[store.id]) {
                storeCounts[store.id].count += 1;
            } else {
                storeCounts[store.id] = {
                    id: store.id,
                    company: store.company,
                    latitude: store.latitude,
                    longitude: store.longitude,
                    name: store.name,
                    location_name: store.location_name,
                    distanceFromUser: store.distanceFromUser,
                    count: 1
                };
            }
        });
    });

    const distinctStores = Object.values(storeCounts);
    return distinctStores;
}


export default Map;

