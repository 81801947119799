import {useEffect, useState} from 'react'
import {Listbox} from '@headlessui/react'

const radiusOptions = [
    {id: 0, name: '+1/4 mile', radius: 0.25, shortName: '+1/4'},
    {id: 1, name: '+1/2 mile', radius: 0.5, shortName: '+1/2'},
    {id: 2, name: '+1 mile', radius: 1, shortName: '+1'},
    {id: 3, name: '+2 miles', radius: 2, shortName: '+2'},
    {id: 4, name: '+3 miles', radius: 3, shortName: '+3'},
    {id: 5, name: '+4 miles', radius: 4, shortName: '+4'},
    {id: 6, name: '+5 miles', radius: 5, shortName: '+5'},
]

const getRadiusOption = (radius) => {
    return radiusOptions.find((option) => option.radius === radius)
}
const RadiusDropdown = ({onRadiusChange, intialRadius, isHomePage = true}) => {
    const [selectedRadius, setSelectedRadius] = useState(getRadiusOption(intialRadius))
    useEffect(() => {
        onRadiusChange(selectedRadius.radius)
    }, [selectedRadius, onRadiusChange]);

    return (
        <Listbox value={selectedRadius} onChange={setSelectedRadius}>
            <div className="relative w-full h-full">
                <Listbox.Button
                    className={`w-full h-full px-3 text-lg `}>
                    {/*{isHomePage ? selectedRadius.name : selectedRadius.shortName}*/}
                    <span className={`${isHomePage ? 'hidden' : 'md:hidden' }`}>{selectedRadius.shortName}</span><span className={`${isHomePage ? 'block':'hidden md:block'}`}>{selectedRadius.name}</span>
                </Listbox.Button>
                <Listbox.Options
                    className={`w-full absolute overflow-auto border-b border-x border-secondary rounded-b-lg bg-white`}>
                    {radiusOptions.map((radiusOption) => (
                        <Listbox.Option
                            className={`p-3 hover:bg-tertiary border-t border-secondary`}
                            key={radiusOption.id}
                            value={radiusOption}
                            disabled={radiusOption.unavailable}
                        >
                            <span className={`${isHomePage ? 'hidden' : 'md:hidden' }`}>{radiusOption.shortName}</span><span className={`${isHomePage ? 'block':'hidden md:block'}`}>{radiusOption.name}</span>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    )
}
export default RadiusDropdown