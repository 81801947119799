import React, {useContext} from 'react';
import {Link} from 'react-router-dom';
import myImage from '../images/Phindit_Mono-cropped.svg';
import '../fonts/fonts.css';
import {AuthContext} from "../context/AuthContext";
import SignOutButton from "../auth/SignOutButton";
import SignInButton from "../auth/SignInButton";
import SearchBarHeader from "./searchbar/SearchBarHeader";

const HeaderBar = ({
                       showNavBar = false,
                       showSearchBar = false,
                       searchQuery,
                       locationStr,
                       radiusInMiles,
                       queryLong,
                       queryLat
                   }) => {

    const {isAuthenticated} = useContext(AuthContext);

    return (
        <>
            <section className="flex w-full  text-gray-700 bg-white shadow justify-center">
                <div
                    className={` flex items-center ${(!showSearchBar || window.innerWidth > 768) ? 'justify-between' : 'justify-center'} py-5 mx-5 md:mx-10 flex-row w-full `}>

                    <div className="relative flex  ">
                        <Link
                            to="/"
                            className={`flex items-center font-medium text-gray-900 w-24  justify-center mb-0 ${showSearchBar ? 'hidden md:block' : 'block'}`}
                        >
                            <img src={myImage} alt="Phind Logo"
                                // style={imageStyle}
                            />
                        </Link>

                        {showNavBar ? (
                            <>
                                <div className={`mx-8 border-l hidden md:block`}></div>
                                <nav
                                    className="flex items-center mb-5 text-base sm:mb-0  mr-8  sm:border-gray-200 space-x-5">
                                    <a href="#_"
                                       className=" font-ls-medium leading-6 text-gray-600 hover:text-gray-900 hidden sm:block ">
                                        Home
                                    </a>
                                    <a href="#_"
                                       className=" font-ls-medium leading-6 text-gray-600 hover:text-gray-900 hidden sm:block">
                                        For Businesses
                                    </a>
                                    <a href="#_"
                                       className=" font-ls-medium leading-6 text-gray-600 hover:text-gray-900 hidden sm:block ">
                                        Contact
                                    </a>
                                </nav>
                            </>) : null}
                    </div>
                    {showSearchBar ? (
                        <div className={` w-full md:w-2/3 h-10 `}>
                            {searchQuery && locationStr && radiusInMiles && queryLat && queryLong && (
                                <SearchBarHeader defaultValue={searchQuery} locationString={locationStr}
                                                 radius={radiusInMiles} resultsPage={showSearchBar} queryLong={queryLong} queryLat={queryLat}/>)}
                        </div>
                    ) : null}
                    {
                        // (!showSearchBar || window.innerWidth > 768) ? (isAuthenticated ? (<SignOutButton/>) : (
                        //     <SignInButton/>)) : null
                        showSearchBar ? (<span className="hidden md:block">{isAuthenticated ? (<SignOutButton/>) : (<SignInButton/>)}</span>) :
                            (<span className="">{isAuthenticated ? (<SignOutButton/>) : (<SignInButton/>)}</span>)

                    }
                </div>
            </section>
        </>
    );
};

export default HeaderBar;
