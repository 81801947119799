import React from 'react';

const LoadingSpinner = () => {
  return (
    <div className="h-full relative top-1/3 left-0 w-full flex justify-center">
      <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
    </div>
  );
};

export default LoadingSpinner;
