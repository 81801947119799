import {useIsAuthenticated, useMsal} from "@azure/msal-react";
import React, {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {msalConfig} from "./authConfig";
import HeaderBar from "../components/HeaderBar";
import {AuthContext} from "../context/AuthContext";

const Login = () => {
    const {instance} = useMsal();
    const navigate = useNavigate();
    const isAuthenticated = useIsAuthenticated();
    const {isYCombinator} = useContext(AuthContext);

    useEffect(() => {

        if(isYCombinator) {
            navigate("/ycIntro");
        }

        if (isAuthenticated) {
            navigate("/");
        }
    }, [isAuthenticated, navigate, isYCombinator]);

    const handleLogin = () => {
        instance.loginRedirect(msalConfig.loginRequest).then((response) => {
            instance.setActiveAccount(response.account);
            instance.handleRedirectPromise().then(() => {
                navigate("/");
            });
        })
    };

    if(isAuthenticated || isYCombinator) {
        return <div></div>;
    }

    return (
        <div className="flex flex-col h-screen">
            <div className="flex-grow"> <br /></div> {/* Spacer div */}

            <div className="m-auto mb-5 border p-8 rounded-lg shadow border-tertiary  items-center justify-center">
                <h1 className="font-ls-light text-2xl md:text-3xl mb-5">Please sign in to use phindit</h1>
                <button
                    className=" w-full px-6 py-3  bg-secondary  text-lg font-ls-medium rounded-lg align-middle "
                    onClick={handleLogin}>Sign In
                </button>
            </div>

            <div className="flex-grow"><br /></div> {/* Spacer div */}
        </div>
    );
}

export default Login;