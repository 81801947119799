import React from "react";
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';


const SearchButton = ( ) => {

    return (
        <button
            type="submit"
            className={`w-full  h-full  text-lg font-ls-medium  rounded-lg justify-center `}
        >
            <MagnifyingGlassIcon className={`h-5 w-5  inline-block`}/>
        </button>
    )
}
export default SearchButton;