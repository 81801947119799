import React from "react";
import {useMsal} from "@azure/msal-react";

function SignOutButton() {
    const { instance } = useMsal();
  
    const handleLogout = () => {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    };
  
    return (
      <button className={`border py-1.5 px-2.5 rounded-lg shadow w-24 ml-8`}
          onClick={handleLogout}>
        Sign Out
      </button>
    );
  }
export default SignOutButton;