import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import HeadlessAutoLocation from "./HeadlessAutoLocation";
import RadiusDropdown from "./RadiusDropdown";
import ProductInput from "./ProductInput";
import SearchButton from "./SearchButton";

const SearchBarHeader = ({defaultValue = '', locationString = '', radius = 3, queryLat = 51.520002, queryLong = -0.093636}) => {

    const [value, setValue] = useState('');
    const [locationStr, setLocationStr] = useState(locationString);
    const [long, setLong] = useState(queryLong); // default longitude
    const [lat, setLat] = useState(queryLat); // default latitude
    const [radiusInMiles, setRadiusInMiles] = useState(radius); // default radius in miles

    const navigate = useNavigate();

    useEffect(() => {
        setValue(defaultValue);
        setLocationStr(locationString);
        console.log(locationString);
    }, [defaultValue, locationString]);


    const handleInputChange = (event) => {
        setValue(event.target.value);
    };

    const handleLocationChange = (location) => {
        console.log(location);
        setLong(location.lng);
        setLat(location.lat);
        setLocationStr(location.address);
    };

    const handleRadiusChange = (radius) => {
        //console.log(radius);
        setRadiusInMiles(radius);

    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        if (locationStr === '') {

            // Get user's location using Geolocation API
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setLong(position.coords.longitude);
                    setLat(position.coords.latitude);
                    setLocationStr("Current Location")
                },
                (error) => {
                    console.error(error);
                }
            );
            console.log("long, lat");
            console.log(long, lat);
        }

        navigate(`/search?q=${encodeURIComponent(value)}&lat=${lat}&long=${long}&r=${radiusInMiles}&locStr=${encodeURIComponent(locationStr)}`);
    };

    return (
        <>
            <form className={` h-full`}
                  onSubmit={handleFormSubmit}>
                <div className="flex flex-row justify-between w-full h-full z-20  md:space-y-0 ">
                    <div className='flex h-full w-4/12 md:w-5/12 border border-secondary rounded-l-lg border-r-0'>
                        <ProductInput value={value} handleInputChange={handleInputChange}/>
                    </div>
                    <div className='flex w-4/12 h-full justify-between border border-secondary'>
                        <HeadlessAutoLocation onLocationChange={handleLocationChange} locationString={locationStr} />
                    </div>
                    <div className='flex w-2/12 h-full justify-between border border-secondary border-l-0'>
                        <RadiusDropdown onRadiusChange={handleRadiusChange} intialRadius={radiusInMiles} isHomePage={false}/>
                    </div>
                    <div className='flex w-2/12 md:w-1/12 h-full justify-between bg-secondary rounded-r-lg'>
                        <SearchButton />
                    </div>
                </div>
            </form>
        </>
    );
};

export default SearchBarHeader;
