import React from 'react';
import {useNavigate} from "react-router-dom";

const YCIntro = () => {

    const navigate = useNavigate();

    function onButtonClick() {
        navigate("/")
    }

    return (

        <div className="flex flex-col h-screen">
            <div className="flex-grow"> <br /></div> {/* Spacer div */}

            <div className="w-2/3 m-auto mb-5 border p-5 rounded-lg shadow border-tertiary">
                <h1 className="font-ls-light text-2xl md:text-3xl mb-3">Hello, potential investor</h1>
                <br />
                <p>
                    We have populated our database with products that have been collected from UK supermarkets ASDA, Waitrose, and Sainsburys.
                    This is test data which we have onboarded to demonstrate that our system works with tens of thousands of products.
                </p>
                <br/>
                <p>
                    Large supermarkets will not be the focus when we look to launch, we are just using this data for demo purposes. Our initial focus will be on smaller local shops.
                </p>
                <br/>
                <p>
                    We've loaded data that covers the London area only, so search around there to try out phindit.
                </p>
                <br/>
                <button className="px-6 py-3 bg-secondary text-lg font-ls-medium rounded-lg align-middle" onClick={onButtonClick}>
                    Proceed to phindit
                </button>
            </div>

            <div className="flex-grow"><br /></div> {/* Spacer div */}
        </div>
    )
}


export default YCIntro;


// <div className="flex flex-col justify-between h-screen w-3/4 m-auto">
//     <div className="flex-grow flex flex-col sm:flex-row flex-1 ">
//         <div className="flex-2  flex-shrink"></div>
//         <div className="flex flex-col flex-1  ml-0.5 flex-shrink justify-center items-center">
//             <div className="flex flex-col border p-5 rounded-lg shadow border-tertiary justify-center items-center" >
//                 <h1 className="font-ls-light text-2xl md:text-3xl mb-3">A short note about our demo</h1>
//
//                 <p>
//                     Lorem ipsum...
//                 </p>
//
//                 <br />
//
//                 <button
//                     className=" w-96 px-6 py-3  bg-secondary  text-lg font-ls-medium rounded-lg align-middle "
//                     onClick={onButtonClick}>Proceed to phindit
//                 </button>
//             </div>
//         </div>
//         <div className="flex-2  ml-0.5 flex-shrink"></div>
//     </div>
//     <div className=" "></div>
// </div>