import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import HeaderBar from '../HeaderBar';
import Map from './Map';
import Spinner from '../common/Spinner';
import StoreCardList from './StoreCardList';
import search from "./fetchResults";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../../auth/authConfig";
import {InteractionRequiredAuthError, InteractionStatus} from "@azure/msal-browser";


const Results = () => {

  const { instance, inProgress, accounts } = useMsal();

  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [radiusInMiles, setRadiusInMiles] = useState();
  const [products, setProducts] = useState([]);
  const [showLoadingSpinner, setShowLoadingSpinner] = useState(false);
  const [dataHasLoaded, setHasDataLoaded] = useState(false);
  const [locationStr, setLocationStr] = useState();
  const [storesWithProducts, setStoresWithProducts] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  const handleStoreClick = (store) => {
    setSelectedStore(store);
  }

  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('q');
    setSearchQuery(query);

    const lat = queryParams.get('lat');
    setLat(parseFloat(lat));

    const long = queryParams.get('long');
    setLong(parseFloat(long));

    const locStr = queryParams.get('locStr');
    setLocationStr(decodeURIComponent(locStr));

    const radiusInMiles = queryParams.get('r');
    setRadiusInMiles(parseFloat(radiusInMiles));

    const fetchProducts = async () => {
      setShowLoadingSpinner(true);
      if(inProgress === InteractionStatus.None) {
        console.log('in progress')
        let token;
        try {
          token = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });
        } catch (error) {
          if (error instanceof InteractionRequiredAuthError) {
            // Use acquireTokenPopup or acquireTokenRedirect here
            token = await instance.acquireTokenRedirect(loginRequest);
          } else {
            throw error;
          }
        }
        const data = await search(token.accessToken, query, lat, long, radiusInMiles);
        setProducts(data);
        setStoresWithProducts(uniqueStoresWithProducts(data));
        setShowLoadingSpinner(false);
        setHasDataLoaded(true);
      } else {
        console.log(inProgress);
      }
    };

    fetchProducts();
  }, [location.search, instance, accounts, inProgress,]);

  const uniqueStoresWithProducts = (items) => {
    // First, create a flat array of { store, product } objects
    var flatData = items.flatMap(item =>
      item.store.map(store => ({
        store: store,
        product: item.product
      }))
    );

    //Then, reduce this array into the desired format
    const result = flatData.reduce((acc, { store, product }) => {
      const foundStore = acc.find(s => s.store.id === store.id);
      if (foundStore) {
        // If store already exists in result, just push the product
        foundStore.products.push(product);
      } else {
        // If store does not exist in result, add it with the product
        acc.push({
          store: store,
          products: [product]
        });
      }
      return acc;
    }, []);
    return result;

  }

  return (
    <>
      <div className="flex flex-col md:h-screen ">
        <div className="z-20 ">
          <HeaderBar showNavBar={false} showSearchBar={true} searchQuery={searchQuery} locationStr={locationStr} radiusInMiles={radiusInMiles} queryLat = {lat} queryLong = {long}/>
        </div>
        {showLoadingSpinner ?
          (
            <div className="h-full">
              <Spinner />
            </div>
          ) :
          dataHasLoaded ?
            (
              <div className="flex flex-col-reverse md:flex-row  md:overflow-hidden md:h-screen">
                <div className=" w-full h-full px-1.5 py-0  md:p-4 md:w-2/3  md:overflow-y-auto custom-scrollbar">
                  <StoreCardList stores={storesWithProducts} searchQuery={searchQuery} selectedStore={selectedStore} storeCardClick={handleStoreClick}/>
                </div>
                <div className=" w-full h-56 md:h-full z-10 ">
                  <Map lat={lat} long={long} results={products} selectedStore={selectedStore} handleStoreClick={handleStoreClick} />
                </div>
              </div>
            ) : null
        }
      </div>
    </>
  );


};

export default Results;